"use client";

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios, { AxiosResponse } from "axios";
import { useSearchParams } from "next/navigation";
import { usePostHog } from "posthog-js/react";

import { useAuth } from "@auth/client-sdk-react";
import {
  loadMentionMeReferreroffer,
} from "apps/website/utils/mentionme/loadMentionMe";
import { useAPI } from "apps/website/hooks/useAPI";

import { OrderData } from "../api";
import { RC_PURCHASED } from "../trackers/triggers/handlers/gtm";

const sleep = (n: number) => new Promise((res) => {
  setTimeout(res, n);
});

declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

// What does this hook do?
// 1. Loads first order data, via 10 retries with 2 sec in between.
//    - Pass it flow name for some reason as well?
// 2. Fire mention me event. Optionally, trigger mention me popup
// 3. Populate auth cookies if they are returned
// 4. Fire GTM event
// 5. Remove rc_purchased cookie

export function useTrackSuccessfulPurchase(
  {
    inFlow,
    deliveryDateSetter,
    catNamesSetter,
    doNotShowMentionMePopup,
  }: {
    inFlow?: string,
    deliveryDateSetter?: (date: (string | undefined)) => void,
    catNamesSetter?: (catNames: (string | undefined)) => void,
    doNotShowMentionMePopup?: boolean,
  } = {},
) {
  const [ firstOrder, setFirstOrder ] = useState<AxiosResponse<OrderData>>();
  const [ katkinCustomerId, setKatkinCustomerId ] = useState<string | null>();
  const [ isLoading, setIsLoading ] = useState(true);
  const searchParams = useSearchParams();
  const api = useAPI();
  const auth = useAuth();
  const [
    cookies,
    setCookie,
    removeCookie,
  ] = useCookies();
  const [ logPurchaseHasFired, setLogPurchaseHasFired ] = useState(false);

  const posthog = usePostHog();

  useEffect(() => {
    // Do not run during static generation
    if (typeof window === undefined) return;
    window.dataLayer = window.dataLayer || [];
  }, [ window ]);

  useEffect(() => {
    async function logPurchase() {
      const getKatkinCustomerId = searchParams.get("katkinCustomerId");
      const flow = searchParams.get("flow");
      setKatkinCustomerId(getKatkinCustomerId);

      if (!getKatkinCustomerId || Array.isArray(getKatkinCustomerId)) {
        console.error("no katkinCustomerId provided. katkinCustomerId=", katkinCustomerId);
        setIsLoading(false);
        return;
      }
      if (Array.isArray(flow)) {
        console.error("multiple flows provided. flow=", flow);
        setIsLoading(false);
        return;
      }
      // Because it takes time for the recharge/shopify webhooks to run, we retry until we get a successful result
      let retries = 10;
      while (retries > 0) {
        try {
          if (!firstOrder) {
            const res = await api.FreshMealPlan
              .getCustomerFirstOrder(getKatkinCustomerId, inFlow ?? (flow || undefined));
            if (res.status === 200) {
              setFirstOrder(res);
              setIsLoading(false);
            }
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            if (e?.response?.status === 200) {
              console.error(`axiosError: failed to get customer data for [katkinCustomerId=${katkinCustomerId}]`, e);
              return;
            }
            console.error(`axiosError: bad response when getting customer data for [katkinCustomerId=${katkinCustomerId}], retrying...`, e);

          } else {
            console.error(`failed to get customer data for [katkinCustomerId=${katkinCustomerId}]`, e);
            return;
          }
        }
        await sleep(2000);
        retries -= 1;
      }
      setIsLoading(false);
    }

    if (cookies && posthog && typeof window !== undefined && window?.dataLayer) {
      void logPurchase();
    }
    // We don't want auth to be in the dependencies, since that will cause the script to rerun when the user logs in.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cookies[RC_PURCHASED],
    inFlow,
    api,
    searchParams,
    posthog,
  ]);

  useEffect(() => {

    const effectLogPurchase = async () => {
      if (logPurchaseHasFired) return;
      if (firstOrder && katkinCustomerId) {
        if (deliveryDateSetter) deliveryDateSetter(firstOrder.data.deliveryDate);
        if (catNamesSetter) catNamesSetter(firstOrder.data.catNames);
        loadMentionMeReferreroffer({
          firstname: firstOrder.data.firstName,
          surname: firstOrder.data.lastName,
          email: firstOrder.data.charge.email,
          order_number: firstOrder.data.charge.id,
          order_subtotal: firstOrder.data.price_no_vat,
          order_currency: "GBP",
          order_date: firstOrder.data.charge.processed_at_instant || firstOrder.data.charge.created_at_instant,
          customer_id: katkinCustomerId,
          coupon_code: firstOrder.data.discountCode,
          phone_number: firstOrder.data.charge.shipping_address?.phone,
          fullname: firstOrder.data.fullName,
          address_line1: firstOrder.data.charge.shipping_address?.address1,
          address_line2: firstOrder.data.charge.shipping_address?.address2,
          address_city: firstOrder.data.charge.shipping_address?.city,
          address_county: firstOrder.data.charge.shipping_address?.province,
          address_postcode: firstOrder.data.charge.shipping_address?.zip,
          address_country: "United Kingdom",
          custom_share_field: firstOrder.data.catNames,
          custom_field: firstOrder.data.catNames,
          // We want to not show the mentionMe popup but still emit the MentionMe event
          // so that the use of a referral code is tracked. We change implementation to
          // link, since there is (usually) no mmWrapper tag in welcome pages meaning no
          // link will be inserted.
          implementation: doNotShowMentionMePopup ? "link" : "popup",
        });

        if (!firstOrder.data.gtmData) {
          console.error("No GTM data provided");
          return;
        }
        if (firstOrder.data.authData) {
          if (firstOrder.data.authData.access_token && firstOrder.data.authData.refresh_token) {
            console.log("Forcing log in, useTrackSuccessfulPurchase"); // TODO: remove
            await auth.forceLogin(firstOrder.data.authData, true);
          }
        }
        if (!cookies[RC_PURCHASED]) return;
        setLogPurchaseHasFired(true);
        const { kktransactionId } = firstOrder.data.gtmData;
        if (!cookies[kktransactionId]) {
          setCookie(cookies[kktransactionId], "true", {
            path: "/",
          });
          if (typeof window !== undefined && window?.dataLayer) {
            try {
              window.dataLayer.push(firstOrder.data.gtmData);
            } catch (e) {
              console.error("failed to push firstOrder.gtmData");
              console.error(e);
            }
          }
          if (firstOrder.data.gtmDataGA4) {
            if (typeof window !== undefined && window?.dataLayer) {
              try {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push(firstOrder.data.gtmDataGA4);
              } catch (e) {
                console.error("failed to push firstOrder.gtmDataGA4");
                console.error(e);
              }
            }
            posthog.capture(
              "purchase",
              firstOrder.data.gtmDataGA4?.ecommerce,
            );
          }
          removeCookie(RC_PURCHASED, {
            path: "/",
          });
          // The cookie is set to true when a purchase is complete.
          // Once the purchase is logged to analytics the cookie is removed.
        }
      }
    };

    if (firstOrder && katkinCustomerId) {
      void effectLogPurchase();
    }
  }, [ firstOrder, katkinCustomerId ]);

  return {
    firstOrder,
    isLoading,
  };

}
